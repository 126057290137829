
@media screen and (min-width: 1153px) {
    .gx-hide-768 {
        display: none !important;
    }
}

.gx-ml-2 {
    margin-left: 0.5rem !important;
}

.gx-mr-3 {
    margin-right: 1rem !important;
}

.gx-ml-3 {
    margin-left: 1rem !important;
}

.gx-mb-4 {
    margin-bottom: 1.5rem !important;
}

.ant-menu {
  background-color: #fffffc;
  color: black;
  height: 100%;
}

.ant-layout-header {
    height: 40px;
    padding-inline: 50px;
    color: rgba(0, 0, 0, 0.88);
    line-height: 64px;
    background: #fafafa;
}

.ant-layout-sider {
    background-color: #fffffc;
    color: #545454;
    flex: 0 0 auto !important;
    max-width: none !important;
    min-width: 10px !important;
    width: 280px !important;
    height: 100%;
    overflow: auto;
}


.gx-layout-sider-header {
    padding: 10px 30px 10px 70px;
    height: 72px;
    box-shadow: 0 0 4px 4px rgb(250, 249, 249);
    position: relative;
    z-index: 1;
    display: -ms-flex;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    -ms-align-items: center;
    align-items: center;
}

.ant-layout .ant-layout-sider-children {
    height: 100%;
    padding-top: 0.1px;
    overflow: auto;
    background-color: #fffffc;
}


.gx-layout-sider-header .gx-linebar {
    position: absolute;
    left: 20px;
    z-index: 1;
    top: 15px;
    transition: all 0.3s ease-out;
}

@media screen and (min-width: 1153px) {
    .gx-layout-sider-header .gx-linebar {
        display: none;
    }

}

.gx-layout-sider-dark .gx-layout-sider-header {
    background-color: rgba(247, 237, 237, 0.932);
    box-shadow: none;
}

.gx-sidebar-notifications {
    padding: 30px 10px 10px;
    margin: 0 20px 10px;
    border-bottom: solid 1px #e8e8e8;
}

.ant-layout-sider-dark .gx-sidebar-notifications {
    border-bottom-color: #9799ac;
}


  .ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
    background-color: #f69c3cd0;
  }

  .ant-btn-primary {
    background-color: #fa8c15;
    font-weight: 600;
  }

  .ant-menu-dark .ant-menu-submenu-selected .ant-menu-submenu-title {
    color: #fa8c15;
    font-weight: 600;
}

.side-icon{
    display: flex;
    flex-direction: row;
    gap: 2%;
}

.ant-menu .ant-menu-item-selected {
    background-color: #f5e9d1c6;
    color: black;
}

.side-bar-background {
    background: none;
}

.ant-menu-dark .ant-menu-submenu-title:hover:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected){
    color: black;
}

.ant-menu-dark .ant-menu-item a:hover{
    color: black;
}


.ant-menu-dark .ant-menu-item, .ant-menu .ant-menu-submenu-title {
    color: black;
}


.ant-menu-submenu-title {
    color: black;
}


