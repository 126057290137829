.ant-layout-sider-children{
    background-color: #FAFAFA;
    padding-right: 3%;
}
.ant-menu{
    background-color: #FAFAFA;
    color: black;
}
.ant-menu-dark.ant-menu-inline .ant-menu-sub.ant-menu-inline, :where(.css-dev-only-do-not-override-14wwjjs).ant-menu-dark>.ant-menu.ant-menu-inline .ant-menu-sub.ant-menu-inline {
    background: #FAFAFA;
}
.ant-layout{
margin:1% 0 1% 0;
background-color: #FAFAFA;
}

.ant-layout.ant-layout-has-sider {
  flex-direction: row;
  height: 100%;
}

.ant-layout-footer {
  padding: 24px 50px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  background: #f5f5f5;
}

@media (max-width: 600px) {
  .ant-picker-panels {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}


.header-background{
    background-color: #FAFAFA;
}

    @media (max-width: 1152px) {
    .layout-dashboard .ant-layout-footer {
        width: auto;
    }
    .layout-dashboard .ant-layout {
        width: auto;
        flex-shrink: 1;
    }
    .side-bar{
        display: none;
    }
    .ant-layout-header {
        height: 45px;
        padding-inline: 15px;
        color: rgba(0, 0, 0, 0.88);
        line-height: 64px;
        background: #ffffff;
      }
      .profile-contain{
        width: 100%;
      }
      .sidebar-toggler{
        color: black;
      }

      .gx-icon-btn .icon .icon-menu{
        color: black;
    }
  }

  @media (min-width: 1153px) {
    .layout-dashboard .ant-layout-footer {
      margin: 0 0 20px 20px;
    }
    .sider-primary {
      margin: 20px 0 0 20px;
      padding: 13px 20px;
      height: calc(100vh - 20px);
    }


    .layout-dashboard .ant-layout {
      width: auto;
      flex-shrink: 1;
    }
    .sidebar-toggler {
      display: none;
    }
    .ant-layout-header {
        display: none;
    }
    .profile-contain{
      width: 60%;
    }
    .gx-icon-btn .icon .icon-menu{
      display: none;
  }
  }

  .ant-btn-primary.ant-btn-background-ghost {
    color: #e07707e2;
    background-color: transparent;
    border-color: #e07707e2;
    box-shadow: none;
  }

  .ant-btn-primary.ant-btn-background-ghost:not(:disabled):not(.ant-btn-disabled):hover{
    color: #e07707e2;
    background-color: transparent;
    border-color: #e07707e2;
    box-shadow: none;
  }

  .icon-placeout{
    display: flex;
    place-content: center;
  }

  @media (max-width: 600px) {
    .ant-picker-panels {
      display: flex;
      flex-direction: column;
      height: 50;
    }

    .displayNone {
      display: none;
    }
  }

  /* .ant-slider .ant-slider-track {
    position: absolute;
    background-color: #fa8c15;
    border-radius: 2px;
    transition: background-color 0.2s;
}

.ant-slider-handle .ant-slider-handle-1{
  box-shadow: 0 0 0 2px #fa8c15
} */


