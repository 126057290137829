body {
  width: 100%;
  height: 100%;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}


button,
input {
  overflow: visible;
}

#root {
  height: 100vh;
}

img {
  max-width: 100%;
}

.ant-form-horizontal .ant-row {
  margin-left: -15px;
  margin-right: -15px;
}


.gx-app-login-wrap {
  height: 100%;
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  -ms-justify-content: center;
  justify-content: center;
  overflow-x: hidden;
}

.gx-app-login-container {
  position: relative;
  max-width: 680px;
  width: 94%;
  margin: 0 auto;
}

@media screen and (max-width: 575px) {
  .gx-app-login-container {
    padding-bottom: 20px;
  }
}

.gx-app-login-main-content {
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: #fff;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  border-radius: 12;
  font-size: 14px;
  overflow: hidden;
}

.gx-app-login-content {
  padding: 35px 35px 20px;
  width: 60%;
}
.gx-app-login-content .ant-input {
  background-color: #f5f5f5;
}
.gx-app-login-content .ant-input:focus {
  box-shadow: none;
  border-color: #fa8c15;
}
@media screen and (max-width: 575px) {
  .gx-app-login-content {
    width: 100%;
    padding: 20px 20px 10px;
  }
}

.gx-app-logo-content {
  color: #ffffff;
  padding: 35px 35px 20px;
  width: 40%;
  position: relative;
  overflow: hidden;
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

img {
  max-width: 100%;
}
@media screen and (max-width: 575px) {
  .gx-app-login-content {
    width: 100%;
    padding: 20px 20px 10px;
  }
}
@media screen and (max-width: 575px) {
  .gx-app-logo-content {
    width: 100%;
    padding: 20px 20px 10px;
  }
}