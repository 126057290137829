@media (max-width: 768px) {
    .hide_labels{
        font-weight: bold;
    }
}

@keyframes slide-in-left {
    from{
      transform: translateX(-200px);
      opacity: 0;
    }
    to{
      transform: translateX(0);
      opacity: 1;
    }
  }

  @keyframes move-above {
    from{
      transform: translateY(70px);
      opacity: 0;
    }
    to{
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes move-down {
    from{
      transform: translateY(-70px);
      opacity: 0;
    }
    to{
      transform: translateY(0);
      opacity: 1;
    }
  }


.animations{
    animation: move-above 0.7s ease-in 0.1s 1 normal both;
}

.card-color-transparent{
    background-color: hsla(120, 60%, 70%, 0.3);
}

.animations-mobile-down{
    animation: move-down 0.7s ease-in 0.1s 1 normal both;
}

.animations-mobile-above{
    animation: move-above 0.7s ease-in 0.1s 1 normal both;
}